
    export default {
        data() {
            return {
                getComponent(component){
                    if(component && component.component){
                        return component.component.includes('system') ? component.component : 'systemHorizontalSliderComponents'+this.replaceText(component.component);
                    }else{
                        return 'systemHorizontalSliderComponentsSimpleImage';
                    }
                },
                replaceText(s){
                    s = s.replace("slider","");
                    return s.charAt(0).toUpperCase() + s.slice(1);
                }
            }
        },
     props : ['blok']
    }
